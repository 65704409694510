<template>
  <div v-if="canAccessPage">
    <v-container pa-0 fluid style="min-height: 25px">
      <v-row justify="end" class="font-weight-black">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" style="text-align: end;">
          <v-badge
              bordered
              color="orange"
              icon="mdi-lock"
              overlap
              v-show="!abrirFiltro"
              v-if="habilitarIconBadge">
              <v-icon v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
          </v-badge>
          <v-icon v-else v-show="!abrirFiltro" @click="abrirFiltro = !abrirFiltro">filter_list</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <notas-debito-list-filtro
      ref="filtros"
      v-model="abrirFiltro"
      @NotasDebitoFiltros__AplicaFiltros="aplicarFiltros"
      @FiltroRapido__HabilitaIconBadge="habilitaIconBadge"/>

    <notas-debito-list-tabela
      ref="tabela"
      v-if="filtros"
      :filtros="filtros"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import NotasDebitoListTabela from '@/spa/notas-debito/contabilizacao/NotasDebitoListTabela';
import NotasDebitoListFiltro from '@/spa/notas-debito/contabilizacao/NotasDebitoListFiltro';

export default {
  data() {
    return {
      filtros: null,
      abrirFiltro: false,
      habilitarIconBadge: false,
    };
  },
  components: {
    NotasDebitoListTabela,
    NotasDebitoListFiltro,
  },
  computed: {
    ...generateComputed('LIQ_DTA_CONT', [
      'canAccessPage',
    ]),
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
  },
  watch: {
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    habilitaIconBadge(valor) {
      this.habilitarIconBadge = valor;
    },
    aplicarFiltros(filtrosAplicados) {
      if (!filtrosAplicados || !this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
    },
    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
<style lang="scss">
</style>
