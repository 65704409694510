<template>
  <div>
    <v-row justify="end" v-show="!esperar">
      <v-menu
        ref="data_contabilizacao_todos"
        v-if="canAccessCRUD && listaNotasDebito.length"
        :close-on-content-click="false"
        :nudge-right="40"
        v-model="menuAteraDataSelecionados"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-on="{ ...tooltip, ...menu }"
                class="mr-3 mb-3"
                id="btn_salvar"
                color="primary"
                :disabled="!canAccessCRUD || !selected.length">
                <v-icon class="mr-2">event</v-icon>
                {{ $t('label.definir_data') }}
              </v-btn>
            </template>
            <span>{{ $t('message.definir_todas_data_contabilizacao') }}</span>
          </v-tooltip>
        </template>
        <v-date-picker
          locale="pt-br"
          color="primary"
          scrollable
          v-model="dataContabilizacaoAll">
          <v-spacer></v-spacer>
          <v-btn text color="primary"
            @click.native="menuAteraDataSelecionados = false">
              {{ $t('label.cancelar') }}
          </v-btn>
          <v-btn text color="primary"
            @click.native="aplicarDataNotasSelecionadas">
            {{ $t('label.aplicar') }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-row>
    <v-container fluid px-0 py-2 class="Container_Tabela">
      <v-row align="center" justify="start">
        <v-col cols="12" sm="12">
          <v-data-table
            v-show="!esperar"
            class="elevation-1"
            id="tabela_notas_debito"
            v-model="selected"
            :headers="headers"
            show-select
            :items="listaNotasDebito"
            :options.sync="pagination"
            :server-items-length="totalPage"
            item-key="idExterno"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50],
            }"
            >
            <template v-slot:item.id_externo="{ item }">
              {{ item.idExterno }}
            </template>
            <template v-slot:item.cnpj="{ item }">
              <div style="white-space: nowrap">
                {{ item.cnpj }}
              </div>
            </template>
            <template v-slot:item.nome_fornecedor="{ item }">
              {{ item.nomeFornecedor }}
            </template>
            <template v-slot:item.valor_nota_debito="{ item }">
              <div style="white-space: nowrap">
                {{ getMoney(item.valorNotaDebito) }}
              </div>
            </template>
            <template v-slot:item.data_emissao="{ item }">
              {{ item.dataEmissao }}
            </template>
            <template v-slot:item.data_vencimento="{ item }">
              {{ item.dataVencimento }}
            </template>
            <template v-slot:item.data_contabilizacao="{ item }" class="Botoes_Acao" nowrap :class="['text-sm-left']" @click.stop>
              <v-menu
                :disabled="!canAccessCRUD"
                :key="item.id"
                v-model="item.menu"
                ref="data_contabilizacao"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :id="`tabela_notas_debito_data_${item.idExterno}`"
                    :disabled="!canAccessCRUD"
                    class="CalendarioForm-data__text"
                    readonly
                    clearable
                    v-model="item.dataFormatada"
                    prepend-icon="event">
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  scrollable
                  @input="setaDataFormatada(item)"
                  v-model="item.dataContabilizacao"></v-date-picker>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    </v-container>
    <v-row justify="end" v-show="!esperar">
      <v-btn
        right
        class="mr-3 mt-3"
        id="btn_salvar"
        @click.native="openSaveDialog('alteraNotaUnica')"
        color="primary"
        v-if="canAccessCRUD && listaNotasDebito.length">
        {{ $t('label.salvar') }}
      </v-btn>
    </v-row>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('title.nota_debito', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>
  </div>
</template>

<script>
import moment from 'moment';
import {
  getMoney,
} from '@/common/functions/helpers';
import { listarNotasDebitoPendentes } from '@/common/resources/notas-debito-contabilizacao';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  components: {
    Confirm,
  },
  props: {
    filtros: Object,
  },
  data() {
    return {
      resource: this.$api.notasDebitoContabilizacao(this.$resource),
      mensagemConfirmacao: '',
      menuAteraDataSelecionados: false,
      dataContabilizacaoAll: new Date().toISOString().substr(0, 10),
      listaNotasDebito: [],
      selected: [],
      filtrosAplicados: this.filtros,
      pagination: {},
      totalPage: 0,
      esperar: false,

      headers: [
        { text: this.$tc('label.codigo', 1), value: 'id_externo', sortable: true },
        { text: this.$tc('label.cnpj', 1), value: 'cnpj', sortable: true },
        { text: this.$tc('label.nome_fornecedor', 1), value: 'nome_fornecedor', sortable: true },
        { text: this.$tc('label.valor', 1), value: 'valor_nota_debito', sortable: true },
        { text: this.$tc('label.unidade_negocio', 1), value: 'unidadeNegocio', sortable: false },
        { text: this.$tc('label.tipo_acao', 1), value: 'tipoAcao', sortable: false },
        { text: this.$tc('label.tipo_uso', 1), value: 'tipoUso', sortable: false },
        { text: this.$tc('label.data_contabilizacao', 1), value: 'data_contabilizacao', sortable: false },
        { text: this.$tc('label.data_emissao', 1), value: 'data_emissao', sortable: true },
        { text: this.$tc('label.data_vencimento', 1), value: 'data_vencimento', sortable: true },
      ],

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  computed: {
    ...generateComputed('LIQ_DTA_CONT', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    buscar() {
      if (this.esperar || !this.filtroPeriodoValido(this.filtrosAplicados)) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscarDados();
      }, 1000);
    },

    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
        status: 'PENDENTE',
      };

      listarNotasDebitoPendentes(params, this.$resource)
        .then((response) => {
          this.listaNotasDebito = response.data.resposta;
          this.totalPage = response.data.quantidadeRegistrosPagina;
          this.pararEsperar();
        })
        .catch((err) => {
          this.pararEsperar();
          this.$error(this, err);
        });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },

    filtroPeriodoValido(filtrosAplicados) {
      if (filtrosAplicados.data_inicio || filtrosAplicados.data_fim) {
        let dtInicio = '';
        let dtFinal = '';
        dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYY-MM-DD');
        dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');

        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
        if ((filtrosAplicados.data_inicio && !filtrosAplicados.data_fim)
          || (!filtrosAplicados.data_inicio && filtrosAplicados.data_fim)) {
          this.$toast(this.$t('message.data_final_e_inicial'));
          return false;
        }
        return true;
      }
      return true;
    },

    setaDataFormatada(item, dataContabilizacaoParam = null) {
      if (!dataContabilizacaoParam) {
        item.dataFormatada = this.retornaDataFormatada(item.dataContabilizacao);
      } else {
        item.dataFormatada = this.retornaDataFormatada(dataContabilizacaoParam);
      }
      item.menu = false;
    },

    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },

    openSaveDialog(acao) {
      if (acao === 'alteraNotaUnica') {
        setTimeout(() => this.$refs.confirmDialog.open());
      }
    },

    salvar() {
      const listaNotas = [];
      this.listaNotasDebito.forEach((nota) => {
        if (nota.dataContabilizacao && nota.dataFormatada) {
          const notaContabilizacao = {};
          notaContabilizacao.id = nota.id;
          notaContabilizacao.idFornecedorPagador = nota.idFornecedorPagador;
          notaContabilizacao.idContrato = nota.idContrato;
          notaContabilizacao.dataContabilizacao = nota.dataContabilizacao;
          notaContabilizacao.dataEmissao = nota.dataEmissao;

          listaNotas.push(notaContabilizacao);
        }
      });

      if (listaNotas.length) {
        this.resource.atualizarNotasDebitoPendentes(listaNotas)
          .then(() => {
            this.selected = [];
            this.pagination.page = 1;
            this.buscar();
            this.$emit('NotasDebitoTabela__AplicaFiltros');
            this.$toast(this.$t('label.registros_atualizados'));
          })
          .catch((err) => {
            this.$error(this, err);
          });
      } else {
        this.$toast(this.$t('message.nenhum_registro_alterado'));
      }
    },

    aplicarDataNotasSelecionadas() {
      const notasSelecionadas = this.listaNotasDebito.filter((nota) => {
        for (let i = 0; i < this.selected.length; i += 1) {
          if (nota.id === this.selected[i].id) {
            return true;
          }
        }
        return false;
      });
      notasSelecionadas.forEach((nota) => {
        nota.dataContabilizacao = this.dataContabilizacaoAll;
        this.setaDataFormatada(nota, this.dataContabilizacaoAll);
      });
      this.selected = [];
      this.menuAteraDataSelecionados = false;
    },
  },
};
</script>

<style>
.Container_Tabela table.v-table tbody td:first-child,.Container_Tabela  table.v-table tbody td:not(:first-child),
  .Container_Tabela table.v-table tbody th:first-child, .Container_Tabela table.v-table tbody th:not(:first-child),
  .Container_Tabela table.v-table thead td:first-child, .Container_Tabela table.v-table thead td:not(:first-child),
  .Container_Tabela table.v-table thead th:first-child, .Container_Tabela table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
</style>
